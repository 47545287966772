.root {
    background-color: #F8FAFB;
    display: flex;
    height: 100vh;
    overflow: hidden;
    width: 100vw;
    /*background: #F8FAFF;*/
}

.layout {
    width: 100%;
    height: 100%;
    position: relative;
}

.wrapper {
    display: flex;
    align-items: stretch;
    overflow: hidden;
    height: 100%;
    transition: all 0.5s;
}


.navbar {
    min-width: 255px;
    height: 100%;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    /*border-right: 1px solid #E5E5E5;*/
    transition: width 0.3s;
}

.navbarCollapsed {
    height: 100%;
    display: flex;
    flex-direction: column;
    max-width: 120px !important;
    width: 120px !important;
    transition: width 0.3s;
}

.content {
    height: 100%;
    flex-grow: 1;
    overflow: auto;
}